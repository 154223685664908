<template>
  <div class="h-screen flex overflow-y-auto bg-gray-100">
    <div class="flex flex-col w-0 flex-1">
      <home-header full />
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="bg-white">
          <div class="bg-gray-100 inline-block m-4 lg:m-8 p-4 lg:p-8">
            <p
              class="text-black italic font-sans font-bold text-shadow text-md lg:text-xl my-4 lg:my-8"
            >
              Our Story...
            </p>
            <div class="block xl:hidden">
              <img
                src="../../assets/about_knight_topright.png"
                class="float-right max-w-1/2 sm:max-w-2/5 lg:max-w-1/3 xl:max-w-1/4"
              />
              <div
                class="text-red-700 font-serif font-bold text-sm md:text-base lg:text-lg pl-4 lg:pl-8 pb-2 lg:mb-4 xl:mb-8"
              >
                <p class="mb-4 mt-2">
                  The Knights Templar were founded in 1118 A.D to protect the
                  pilgrimage routes leading to Jerusalem and other Christian
                  places of worship in the Holy Land.
                </p>
                <p class="my-4">
                  During the reign of King Baldwin II, the Knights Templar were
                  given quarters on the sacred Temple Mount. Men of nobility
                  were attracted to join this highly respected and impactful
                  Order. Those who were unable to join often endowed the
                  Templars with land and other valuables.
                </p>
                <p class="my-4">
                  The Order grew in strength and influence and evolved into a
                  multinational organization, in addition to being a Chivalric
                  Order of Warrior Monks. Its non-military operations included
                  logistics, farming, winery, construction, operating a fleet of
                  ships and banking services.
                </p>
                <p class="my-4">
                  The historic Knights of the Order of St John of Jerusalem
                  (Knight Hospitallers) were founded in Jerusalem about 1099 A.D
                  during the first crusade by an association of several pious
                  Knights and the Brethren of St. John Hospital, which was
                  established earlier for providing relief to pilgrims
                  travelling to worship at the Holy Sepulchre in Jerusalem.
                </p>
              </div>
            </div>
            <div class="hidden xl:block">
              <div
                class="flex text-red-700 font-serif font-bold text-sm md:text-base lg:text-lg pl-4 lg:pl-8 pb-2 lg:mb-4 xl:mb-8"
              >
                <div class="">
                  <p class="mb-4 mt-2">
                    The Knights Templar were founded in 1118 A.D to protect the
                    pilgrimage routes leading to Jerusalem and other Christian
                    places of worship in the Holy Land.
                  </p>
                  <p class="my-4">
                    During the reign of King Baldwin II, the Knights Templar
                    were given quarters on the sacred Temple Mount. Men of
                    nobility were attracted to join this highly respected and
                    impactful Order. Those who were unable to join often endowed
                    the Templars with land and other valuables.
                  </p>
                  <p class="my-4">
                    The Order grew in strength and influence and evolved into a
                    multinational organization, in addition to being a Chivalric
                    Order of Warrior Monks. Its non-military operations included
                    logistics, farming, winery, construction, operating a fleet
                    of ships and banking services.
                  </p>
                  <p class="my-4">
                    The historic Knights of the Order of St John of Jerusalem
                    (Knight Hospitallers) were founded in Jerusalem about 1099
                    A.D during the first crusade by an association of several
                    pious Knights and the Brethren of St. John Hospital, which
                    was established earlier for providing relief to pilgrims
                    travelling to worship at the Holy Sepulchre in Jerusalem.
                  </p>
                </div>
                <img
                  src="../../assets/about_knight_topright.png"
                  class="max-w-1/2 sm:max-w-2/5 lg:max-w-1/3 xl:max-w-1/4 max-h-80"
                />
              </div>
            </div>
            <hr class="bg-black h-0.5" />
            <p
              class="text-black italic my-4 lg:my-8 font-sans font-bold text-shadow text-center text-base lg:text-lg"
            >
              Masonic Order of Knights Templar and Knights of Malta
            </p>
            <img
              src="../../assets/kt-knight-left.png"
              class="float-left max-w-1/3 pr-2"
            />
            <img
              src="../../assets/kt-knight-right.png"
              class="hidden xl:block float-right max-w-1/3 pl-2"
            />
            <div
              class="text-red-700 font-serif font-bold text-sm md:text-base lg:text-lg pl-4 lg:pl-8 text-justify"
            >
              <p class="mb-4 mt-2">
                Modern Masonic association with the medieval Knights Templar is
                by way of the ceremony of installation in which the candidate
                plays the role of a pilgrim who through a process is elevated to
                Knighthood.
              </p>
              <p class="my-4">
                The earliest reference to Knights Templar in English Masonic
                records is in the minutes of Chapter of Friendship (Royal Arch)
                in Portsmouth in 1778 A.D. where it was worked as an appendant
                degree. A Grand Conclave was established in 1791 A.D. comprising
                seven encampments with Thomas Dunckerley as the Grand Master. In
                1873 A.D., the Grand Conclave became the Great Priory and the
                encampments were renamed Preceptories.
              </p>
              <img
                src="../../assets/kt-knight-right.png"
                class="float-right xl:hidden max-w-1/3"
              />
              <p class="my-4">
                The Malta Degree is conferred on a candidate who has already
                been installed as a Knight Templar making it a progressive
                Degree within the Order of Knights Templar. The ceremony for the
                Knights of Malta is based on the migration of the Knights
                Hospitallers from their original base in Jerusalem via Palestine
                and Rhodes to their final settling on the island of Malta in
                1522 A.D. where they remained in control for 270 years. Hence,
                the full name of our Order is “The United Religious, Military
                and Masonic Orders of the Temple and of St John of Jerusalem,
                Palestine, Rhodes and Malta, in England and Wales and its
                Provinces Overseas”.
              </p>
              <p class="my-4">
                The ceremonies are realistic and memorable. The Regalia is
                impressive and unique being based on that worn by the Medieval
                Knights. Our festive boards also have a special ambience
                simulating a Knights Templar banquet.
              </p>
            </div>
            <div
              class="text-red-700 font-serif font-bold text-center italic text-sm lg:text-lg pl-4 lg:pl-8 "
            >
              <br />
              <p class="mt-4">
                R Em Kt Dr. Brian B Shegar
              </p>
              <p class="mb-4">
                Provincial Prior
              </p>
            </div>
            <hr class="bg-black h-0.5" />
            <div>
              <p
                class="text-black italic my-4 lg:my-8 font-sans font-bold text-shadow text-left text-base lg:text-lg"
              >
                Related Links
              </p>
              <div class="pt-2 pb-4 flex">
                <img
                  src="../../assets/markmasons.png"
                  class="object-contain h-20 sm:h-16 min-w-0"
                />
                <div class="pl-2 sm:pl-4 flex flex-col min-w-0 flex-2">
                  <p
                    class="text-red-700 font-serif font-bold text-tiny md:text-lg lg:text-xl pb-1"
                  >
                    Mark Masons' Hall, London - The Home of Progressive
                    Freemasonry
                  </p>
                  <p>
                    <a
                      class="text-black font-serif font-semibold underline text-sm md:text-base lg:text-lg hover:text-blue-800 break-words"
                      href="https://markmasonshall.org/orders/knights-templar"
                      target="_blank"
                      >https://markmasonshall.org/orders/knights-templar</a
                    >
                  </p>
                </div>
              </div>
              <div class="pt-2 pb-4 flex">
                <img
                  src="../../assets/opp111.png"
                  class="object-contain h-20 sm:h-16 min-w-0"
                />
                <div class="pl-2 sm:pl-4 flex flex-col min-w-0 flex-2">
                  <p
                    class="text-red-700 font-serif font-bold text-tiny md:text-lg lg:text-xl pb-1"
                  >
                    Observance Preceptory and Priory No. 111, Chennai
                  </p>
                  <p>
                    <a
                      class="text-black font-serif font-semibold underline text-sm md:text-base lg:text-lg hover:text-blue-800 break-words"
                      href="http://observance111.org.in/"
                      target="_blank"
                      >http://observance111.org.in/</a
                    >
                  </p>
                </div>
              </div>
              <hr class="bg-black h-0.5" />
              <div>
                <p
                  class="text-black italic my-4 lg:my-8 font-sans font-bold text-shadow text-left text-base lg:text-lg"
                >
                  Regalia Suppliers
                </p>
                <div class="pt-2 pb-4 flex">
                  <div class="pl-2 sm:pl-4 flex flex-col min-w-0 flex-2">
                    <p
                      class="text-red-700 font-serif font-bold text-tiny md:text-lg lg:text-xl pb-1"
                    >
                      Southern Regalia
                    </p>
                    <p>
                      <a
                        class="text-black font-serif font-semibold underline text-sm md:text-base lg:text-lg hover:text-blue-800 break-words"
                        href="https://www.southernregalia.com/index.php?main_page=products_all"
                        target="_blank"
                        >https://www.southernregalia.com/index.php?main_page=products_all</a
                      >
                    </p>
                  </div>
                </div>
                <div class="pt-2 pb-4 flex">
                  <div class="pl-2 sm:pl-4 flex flex-col min-w-0 flex-2">
                    <p
                      class="text-red-700 font-serif font-bold text-tiny md:text-lg lg:text-xl pb-1"
                    >
                      KT &amp; KM Regalia
                    </p>
                    <p>
                      <a
                        class="text-black font-serif font-semibold underline text-sm md:text-base lg:text-lg hover:text-blue-800 break-words"
                        href="mailto:ianellston@yahoo.com"
                        target="_blank"
                        >ianellston@yahoo.com</a
                      >
                    </p>
                  </div>
                </div>
                <div class="pt-2 pb-4 flex">
                  <div class="pl-2 sm:pl-4 flex flex-col min-w-0 flex-2">
                    <p
                      class="text-red-700 font-serif font-bold text-tiny md:text-lg lg:text-xl pb-1"
                    >
                      East Regalia
                    </p>
                    <p>
                      <a
                        class="text-black font-serif font-semibold underline text-sm md:text-base lg:text-lg hover:text-blue-800 break-words"
                        href="mailto:lasanne.chris@gmail.com"
                        target="_blank"
                        >lasanne.chris@gmail.com</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <home-footer />
      </main>
    </div>
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";

export default {
  name: "About",
  components: {
    HomeHeader,
    HomeFooter,
  },
};
</script>
