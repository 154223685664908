<template>
  <div class="h-screen flex overflow-y-auto bg-gray-100">
    <div class="flex flex-col w-0 flex-1">
      <home-header full />
      <main
        class="flex flex-col flex-1 relative focus:outline-none bg-gray-300"
        tabindex="0"
      >
        <div
          class="flex-2 w-full bg-gradient-to-b from-red-800 to-red-900 italic text-white inline-block place-content-center lg:py-12 lg:px-8 text-base md:text-xl lg:text-3xl font-semibold"
        >
          <p class="font-serif m-4 text-center">
            Welcome to the official website of the Knights Templar Provincial
            Priory of South East Asia, under the Great Priory of England and
            Wales and its Provinces overseas.
          </p>
          <p class="font-serif m-4 text-center">
            Our Provincial Priory provides the administration and leadership for
            all Preceptories in South East Asia.
          </p>
          <div class="flex justify-center">
            <img
              class="h-40 sm:h-56 lg:h-64 xl:h-88"
              src="../../assets/knighttemplar.gif"
            />
          </div>
        </div>
        <home-footer />
      </main>
    </div>
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";

export default {
  name: "Home",
  components: {
    HomeHeader,
    HomeFooter,
  },
};
</script>
